import PropTypes from "prop-types";
import React from "react";
import FacebookCard from "./FacebookCard";
import GenericTags from "./GenericTags";
import MetadataContainer from "../../containers/MetaDataContainer";
import StructuredData from "./StructuredData";
import TwitterCard from "./TwitterCard";
import { getSocialUrls } from "./helpers";
import removeTrailingSlashes from "../../utilities/removeTrailingSlashes";
import LinkedInCard from "./LinkedInCard";
import InstagramCard from "./InstagramCard";
import config from "../../config.json";
import { brandSettings } from "../StyledComponents/vars";

const SEOComponent = ({
  article,
  person,
  isBlog,
  title,
  description,
  keywords,
  ...overrides
}) => (
  <MetadataContainer>
    {({ location, ...siteMetadata }) => {
      const ref = (type, id) => ({ "@id": id, "@type": type });
      let data = {
        ...siteMetadata,
        description,
        keywords,
        title,
        ...overrides,
      };
      if (!isBlog) {
        if (!data.title) {
          data = { ...data, title: siteMetadata.title };
        }
        if (!data.description) {
          data = { ...data, description: siteMetadata.description };
        }
      }
      const canonicalUrl = removeTrailingSlashes(
        `${data.siteUrl}${location.pathname}`
      );
      const bannerUrl = `${data.siteUrl}${data.banner}?12`;
      const logoUrl = `${data.siteUrl}${data.siteLogo}`;
      const organizationId = `${data.siteUrl}#organization`;
      let personId = null;
      if (person) personId = `${data.siteUrl}#${person.id}`;
      return (
        <>
          <FacebookCard
            description={data.description}
            facebookId={siteMetadata.facebookId}
            image={bannerUrl}
            locale={data.siteLocal}
            name={siteMetadata.title}
            title={data.title}
            type={article ? "article" : "website"}
            url={`${canonicalUrl}/`}
          />
          <TwitterCard
            description={data.description}
            image={bannerUrl}
            title={data.title}
            twitterId={siteMetadata.twitterId}
            username={data.twitter}
          />
          <LinkedInCard
            description={data.description}
            image={bannerUrl}
            name={siteMetadata.title}
            title={data.title}
            url={canonicalUrl}
          />
          <InstagramCard
            description={data.description}
            name={siteMetadata.title}
            title={data.title}
          />
          <GenericTags
            author={
              person
                ? `${person.givenName} ${person.familyName}`
                : siteMetadata.siteName
            }
            description={data.description}
            enableGoogleSearchEnvironment={config.enableGoogleSearchEnvironment}
            googleSiteVerificationCode={config.googleSiteVerificationCode}
            image={bannerUrl}
            keywords={keywords}
            language={data.siteLanguage}
            primaryColor={brandSettings.colorPrimary}
            stage={config.stage}
            title={data.title}
            url={canonicalUrl}
          />
          <StructuredData
            about={data.description}
            author={ref("Organization", organizationId)}
            id={siteMetadata.siteUrl}
            inLanguage={siteMetadata.siteLanguage}
            type="WebSite"
          />
          <StructuredData
            id={organizationId}
            logo={{ "@type": "ImageObject", url: logoUrl }}
            name={siteMetadata.siteName}
            sameAs={getSocialUrls(siteMetadata)}
            type="Organization"
            url={siteMetadata.siteUrl}
          />
          {!!person && (
            <StructuredData
              familyName={person.familyName}
              givenName={person.givenName}
              id={personId}
              jobTitle={person.position}
              name={`${person.givenName} ${person.familyName}`}
              type="Person"
            />
          )}
          <StructuredData
            author={
              article
                ? ref("Person", personId)
                : ref("Organization", organizationId)
            }
            dateModified={data.dateModified}
            datePublished={data.datePublished}
            description={data.description}
            headline={data.title}
            id={canonicalUrl}
            image={bannerUrl}
            inLanguage={siteMetadata.siteLanguage}
            mainEntityOfPage={canonicalUrl}
            publisher={ref("Organization", organizationId)}
            type={article ? "Article" : "WebPage"}
            url={canonicalUrl}
          />
          {/* <StructuredData   // Remove comment when breadcrumbList Needed
            id={`${data.siteUrl}#breadcrumbs`}
            itemListElement={getStructuredBreadcrumbs({
              canonicalUrl,
              isArticle: !!article,
              isPerson: !!person,
              location,
              siteName: data.siteName,
              siteUrl: data.siteUrl,
              title: data.title,
            })}
            type="BreadcrumbList"
          /> */}
        </>
      );
    }}
  </MetadataContainer>
);

SEOComponent.propTypes = {
  article: PropTypes.shape({}),
  description: PropTypes.string,
  isBlog: PropTypes.bool,
  keywords: PropTypes.string,
  pageId: PropTypes.number,
  person: PropTypes.shape({
    familyName: PropTypes.string,
    givenName: PropTypes.string,
    id: PropTypes.string,
    position: PropTypes.string,
  }),
  title: PropTypes.string,
};

SEOComponent.defaultProps = {
  article: null,
  description: null,
  isBlog: false,
  keywords: null,
  pageId: 0,
  person: null,
  title: null,
};

export default SEOComponent;
