import React, { useEffect } from "react";
import { paramCase } from "change-case";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import CareersContainer from "../../containers/CareersContainer";
import PageBanner from "../../sections/PageBanner";
import CardsGrid from "../CardsGrid";
import PageIntro from "../../sections/PageIntro";
import Section from "../Section";
import Box from "../../base/Box";
import MarkdownToHtml from "../MarkdownToHtml";
import ActionButtons from "../ActionButtons";
import PhotoCollage from "../PhotoCollage";
import scrollToSection from "../../utilities/scroll-to";
import EmployeeTestimonialSlider from "../EmployeeTestimonialSlider";
import OurCultureSection from "../../sections/OurCultureSection";

const CareerPage = ({ location }) => (
  <CareersContainer>
    {(careersData, openingsData, cultureData, eventsData, teamMembersData) => {
      const { hash } = location;
      const pageName = "careers";
      const careerContent = careersData
        .map((ele) => (ele.id === pageName ? ele : null))
        .find((el) => el);

      const testimonialList = teamMembersData.filter(
        (member) => member.featuredTestimonial
      );
      useEffect(() => {
        setTimeout(() => {
          scrollToSection(hash.slice(1));
        }, 1000);
      }, [hash]);

      const cultureContent =
        cultureData &&
        cultureData.length > 0 &&
        cultureData.filter((f) => !f.hideCulture);

      const eventsContent =
        eventsData &&
        eventsData.length > 0 &&
        eventsData.filter((f) => !f.hideEvent);

      const updatedCareerContent =
        openingsData &&
        openingsData.length > 0 &&
        openingsData
          .filter((f) => !f.hideOpening)
          .map(
            (data) =>
              data && {
                content: data.brief.content,
                heading: data.brief.heading,
                to: `/careers/${paramCase(data.title)}`,
              }
          );

      const {
        pageBanner,
        introduction,
        testimonials,
        currentOpenings,
      } = careerContent;

      const testimonialsDisplayOrder =
        testimonials?.displayOrder?.length > 0 &&
        testimonials.displayOrder[0].employeeName &&
        testimonials.displayOrder.map((item) => item.employeeName);

      let reorderedTestimonialList = [...testimonialList];
      if (testimonialsDisplayOrder?.length > 0 && testimonialList?.length > 0) {
        reorderedTestimonialList = sortBy(testimonialList, (item) =>
          testimonialsDisplayOrder.indexOf(item.title) !== -1
            ? testimonialsDisplayOrder.indexOf(item.title)
            : testimonialList.length
        );
      }

      return (
        <>
          {pageBanner && !pageBanner.hideSection && (
            <PageBanner
              bannerContent={pageBanner.textContent}
              bgImage={pageBanner.backgroundImage}
            />
          )}
          {introduction && !introduction.hideSection && (
            <PageIntro
              contentWrapperSx={{ width: [null, null, null, "60%"] }}
              data={introduction}
              imageRatio={[[16, 9], null, null, [1, 1]]}
              imageSx={{
                maxWidth: "100%",
              }}
              imageWrapperSx={{
                maxWidth: ["100%", null, null, "40%"],
                mt: [0, null, null, "-15rem"],
              }}
              page={careerContent.id}
              sectionSx={{ bg: "white" }}
            />
          )}

          {cultureContent &&
            cultureContent
              .filter((culture) => !culture.hideSection)
              .map((culture) => <OurCultureSection data={culture.summary} />)}

          {eventsContent &&
            eventsContent.map((event) => {
              const { id, summary, images } = event;
              const isGroupOfFive = images?.length === 5;
              return (
                !event.hideSection && (
                  <Section
                    key={id}
                    containerSx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: ["column", null, null, "row-reverse"],
                    }}
                  >
                    {images &&
                      images.length > 0 &&
                      images.some((i) => i.image) && (
                        <Box
                          sx={{
                            display: "flex",
                            flex: "auto",
                            flexDirection: "column",
                            mb: [3, null, null, 0],
                            width: ["100%", null, null, "70%"],
                          }}
                        >
                          <PhotoCollage
                            collageGrid={isGroupOfFive ? [2, 1, 2] : [1, 2, 1]}
                            images={images.slice(0, 5).map((i) => i.image)}
                          />
                        </Box>
                      )}
                    <Box
                      sx={{
                        pr: [null, null, null, 4],
                        width: [null, null, null, "30%"],
                      }}
                    >
                      <MarkdownToHtml
                        heading={summary?.textContent?.heading}
                        source={summary?.textContent?.content}
                        sx={{
                          p: { color: "text.primary" },
                        }}
                      />
                      {summary.actionButtons &&
                        summary.actionButtons.length > 0 &&
                        summary.actionButtons.some(
                          (i) => !i.hideButton && i.buttonLabel
                        ) && <ActionButtons buttons={summary.actionButtons} />}
                    </Box>
                  </Section>
                )
              );
            })}

          {reorderedTestimonialList?.length > 0 &&
            testimonials &&
            !testimonials.hideSection && (
              <Section
                containerSx={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: [
                    null,
                    "maxWidths.sm",
                    "maxWidths.md",
                    "maxWidths.lg",
                  ],
                }}
                sx={{
                  bg: "primary",
                  color: "white",
                }}
              >
                {!testimonials.heading && (
                  <Box
                    as="h3"
                    sx={{
                      color: "primaryLight",
                      fontFamily: "secondary",
                      fontSize: 2,
                      fontWeight: "bold",
                      letterSpacing: 4,
                      lineHeight: 3,
                      textTransform: "uppercase",
                    }}
                  >
                    Testimonials
                  </Box>
                )}

                {testimonials.heading && (
                  <MarkdownToHtml
                    heading={testimonials.heading}
                    headingSx={{ h2: { color: "white" } }}
                  />
                )}
                <EmployeeTestimonialSlider
                  testimonials={reorderedTestimonialList.map((employee) => {
                    const {
                      title,
                      memberDesignation,
                      testimonial,
                      memberImage,
                    } = employee;
                    const author = `${title ? `#### **${title}**\n\n` : ""}${
                      memberDesignation ? `##### ${memberDesignation}` : ""
                    }`;
                    return {
                      author,
                      content: testimonial,
                      thumb: memberImage,
                    };
                  })}
                />
              </Section>
            )}

          {!currentOpenings.hideSection && (
            <CardsGrid
              data={updatedCareerContent}
              hashName="openings"
              headingSx={{
                ".md_heading": {
                  h2: { color: "white", fontWeight: "light" },
                  h3: { color: "primaryLight" },
                },
              }}
              sectionSx={{
                ":before": {
                  bg: "grays.0",
                  content: "''",
                  display: ["none", null, null, "block"],
                  height: "52rem",
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  zIndex: -1,
                },
                bg: "white",
                position: "relative",
              }}
              sectionTitle={currentOpenings.heading}
            />
          )}
        </>
      );
    }}
  </CareersContainer>
);

CareerPage.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
  }),
};

CareerPage.defaultProps = {
  location: null,
};

export default CareerPage;
