import PropTypes from "prop-types";
import React from "react";
import Box from "../../base/Box";
import MarkdownToHtml from "../../components/MarkdownToHtml";
import Section from "../../components/Section";
import Image from "../../components/Image";
import ActionButtons from "../../components/ActionButtons";

const PointsColumns = ({ sectionSx, data, containerSx }) => {
  const setPointsList = (list) => {
    const dividedList = [];
    const chunkSize = Math.ceil(list.length / 2);
    while (list.length) {
      dividedList.push(list.splice(0, chunkSize));
    }
    return dividedList;
  };

  return (
    <Section
      containerSx={{
        display: "flex",
        flexDirection: "column",
        ...containerSx,
      }}
      sx={{ bg: "white", mb: 0, mt: 0, ...sectionSx }}
    >
      {data.textContent && (
        <MarkdownToHtml
          heading={data.textContent.heading}
          source={data.textContent.content}
          sx={{
            h2: {
              border: ".4rem solid",
              borderColor: "primaryLight",
              borderWidth: "0 0 0 .6rem",
              ml: -2,
              pl: 2,
            },
            h3: { color: "primaryTransparent.1" },
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexWrap: ["wrap", null, null, "nowrap"],
        }}
      >
        <Box sx={{ flex: "auto", my: "auto", p: 3, textAlign: "center" }}>
          <Image img={data.artworkImage} />
        </Box>
        {data.approachList &&
          data.approachList.length > 0 &&
          setPointsList(data.approachList).map((items, index) => (
            <Box
              key={`point-${index}`}
              as="ul"
              sx={{
                flex: "auto",
                maxWidth: [null, null, null, "25%"],
                order: [null, null, null, index === 0 ? "-1" : "1"],
              }}
            >
              {items.map((item, i) => (
                <Box
                  key={`point-content-${index}-${i}`}
                  as="li"
                  sx={{
                    mt: 4,
                  }}
                >
                  <MarkdownToHtml
                    source={item.content}
                    sx={{
                      h3: {
                        color: "primary",
                        fontWeight: "medium",
                        textTransform: "capitalize",
                      },
                    }}
                  />
                </Box>
              ))}
            </Box>
          ))}
      </Box>
      {data.actionButtons &&
        data.actionButtons.length > 0 &&
        data.actionButtons.some((i) => !i.hideButton && i.buttonLabel) && (
          <ActionButtons
            buttons={data.actionButtons}
            wrapperSx={{ display: "flex", justifyContent: "center" }}
          />
        )}
    </Section>
  );
};

PointsColumns.propTypes = {
  containerSx: PropTypes.shape({}),
  data: PropTypes.PropTypes.shape({
    actionButtons: PropTypes.arrayOf(PropTypes.shape({})),
    approachList: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
      })
    ),
    artworkImage: PropTypes.string,
    textContent: PropTypes.shape({
      content: PropTypes.string,
      heading: PropTypes.string,
    }),
  }).isRequired,
  sectionSx: PropTypes.shape({}),
};

PointsColumns.defaultProps = {
  containerSx: {},
  sectionSx: {},
};

export default PointsColumns;
