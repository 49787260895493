import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const AboutPageContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allAboutJson {
          edges {
            node {
              id
              pageId
              pageTitle
              pageBanner {
                hideSection
                textContent {
                  heading
                  content
                }
                backgroundImage
              }
              introduction {
                hideSection
                textContent {
                  heading
                  content
                }
                images {
                  image
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              ourTeam {
                hideEmployees
                hideSection
                textContent {
                  heading
                  content
                }
                hideDesignation
                displayOrder {
                  employeeName
                }
              }
              ourApproach {
                hideSection
                textContent {
                  heading
                  content
                }
                artworkImage
                approachList {
                  content
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              keyCompetencies {
                hideSection
                textContent {
                  heading
                  content
                }
              }
              ourClients {
                hideSection
                textContent {
                  heading
                  content
                }
              }
            }
          }
        }
        allTeamMembersJson(
          sort: { fields: employeeCode, order: ASC }
          filter: { title: { ne: "Default [DO NOT REMOVE OR EDIT]" } }
        ) {
          edges {
            node {
              socialMediaLinks {
                linkedIn
                twitter
                facebook
              }
              employeeCode
              memberDesignation
              hideMember
              memberImage
              memberType
              testimonial
              title
            }
          }
        }
        allServicesJson {
          edges {
            node {
              id
              pageId
              pageTitle
              keyCompetencies {
                textContent {
                  heading
                  content
                }
              }
              services {
                hideService
                serviceName
                keyCompetency
                briefing
              }
            }
          }
        }
        allClientsJson {
          edges {
            node {
              id
              pageTitle
              textContent {
                heading
                content
              }
              clients {
                logo
                clientName
                clientUrl
                testimonial
                authorName
                authorDesignation
              }
            }
          }
        }
        allCultureJson(
          filter: { title: { ne: "Default [DO NOT REMOVE OR EDIT]" } }
        ) {
          edges {
            node {
              hideCulture
              id
              title
              summary {
                actionButtons {
                  buttonLabel
                  buttonPath
                  buttonType
                  externalLink
                  hideButton
                  simpleLink
                }
                images {
                  image
                }
                hideSection
                textContent {
                  content
                  heading
                }
              }
            }
          }
        }
      }
    `}
    render={({
      allAboutJson: { edges: aboutData },
      allServicesJson: { edges: servicesData },
      allClientsJson: { edges: clientsData },
      allCultureJson: { edges: cultureData },
      allTeamMembersJson: { edges: teamMembersData },
    }) =>
      children(
        aboutData.map((e) => ({
          ...e.node,
        })),
        servicesData.map((e) => ({
          ...e.node,
        })),
        clientsData.map((e) => ({
          ...e.node,
        })),
        cultureData.map((e) => ({
          ...e.node,
        })),
        teamMembersData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

AboutPageContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default AboutPageContainer;
