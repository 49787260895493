import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import get from "lodash/get";
import Box from "../../base/Box";
import AppBase from "../AppBase";
import config from "../../config";
import theme from "../../theme";
import Footer from "../Footer";
import Header from "../Header";
import SEOContainer from "../../containers/SEOContainer";
import removeTrailingSlashes from "../../utilities/removeTrailingSlashes";
import SEOComponent from "../SEOComponent";

const Layout = ({ children, ...rest }) => {
  const [hasDom, setHasDom] = useState(false);
  const path = useLocation();

  useEffect(() => {
    if (typeof window !== "undefined") setHasDom(true);
  }, []);

  return (
    <SEOContainer>
      {({ seoData }) => {
        const { pathname } = path;
        const pagePath =
          pathname === "/" ? pathname : removeTrailingSlashes(pathname);
        let title = "";
        let description = "";
        if (pagePath.includes("careers")) {
          if (pagePath === "/careers") {
            const pageSeoData = get(seoData, pagePath, {});
            title = get(pageSeoData, "title", null);
            description = get(pageSeoData, "description", null);
          }
        } else {
          const pageSeoData = get(seoData, pagePath, {});
          title = get(pageSeoData, "title", null);
          description = get(pageSeoData, "description", null);
        }
        return (
          hasDom && (
            <>
              {(title || description) && (
                <SEOComponent description={description} title={title} />
              )}
              <AppBase config={config} theme={theme}>
                <Header />
                <Box as="main">
                  {typeof children === "function" ? children(rest) : children}
                </Box>
                <Footer />
              </AppBase>
            </>
          )
        );
      }}
    </SEOContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export default Layout;
