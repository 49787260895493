import PropTypes from "prop-types";
import React from "react";
import get from "lodash/get";
import seoTitleDescriptionData from "../../../admin-data/seo-title-description/seoContent.json";

const SEOContainer = ({ children }) => (
  <>{children({ seoData: get(seoTitleDescriptionData, "allPages") })}</>
);

SEOContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default SEOContainer;
