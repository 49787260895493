import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { camelCase } from "change-case";
import Box from "../../base/Box";
import AspectRatio from "../../components/AspectRatio";
import Image from "../../components/Image";
import LinkTo from "../../components/LinkTo";
import Section from "../../components/Section";
import Button from "../../components/Button";
import MarkdownToHtml from "../../components/MarkdownToHtml";
import ActionButtons from "../../components/ActionButtons";

const Insights = ({ sectionSx, data, containerSx }) => {
  const [blogData, setData] = useState(null);
  const fetchURL =
    "https://blog.everestek.com/ghost/api/v2/content/posts/?key=cd6951ab0e3a27a612721f3427&order=published_at desc&fields=title,url,feature_image,custom_excerpt&limit=3";
  const getData = () => fetch(`${fetchURL}`).then((res) => res.json());

  useEffect(() => {
    getData().then((blogData) => setData(blogData));
  }, []);
  return (
    <Section
      containerSx={{
        maxWidth: [null, "maxWidths.sm", "maxWidths.md", "maxWidths.lg", null],
        ...containerSx,
      }}
      sx={{ bg: "white", ...sectionSx }}
    >
      {!data?.textContent?.heading && (
        <Box
          as="h2"
          sx={{ color: "primary", fontWeight: "medium", mb: 3 }}
          variant="markdown.h2"
        >
          Insights
        </Box>
      )}
      {data && data.textContent && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 3,
            }}
          >
            <MarkdownToHtml
              contentChildren={
                data.actionButtons &&
                data.actionButtons.length > 0 &&
                data.actionButtons.some(
                  (i) => !i.hideButton && i.buttonLabel
                ) && (
                  <ActionButtons
                    buttons={data.actionButtons}
                    wrapperSx={{
                      mt: 4,
                    }}
                  />
                )
              }
              heading={data.textContent.heading}
              source={data.textContent.content}
              sx={{
                ".md_content": {
                  flex: "auto",
                  width: ["100%", null, null, "50%"],
                },
                ".md_heading": {
                  pr: [null, null, null, 6],
                  width: ["100%", null, null, "50%"],
                },
                display: "flex",
                flexWrap: "wrap",
                h2: { color: "primary", fontWeight: "medium" },
                h3: { color: "primaryMedium" },
              }}
            />
          </Box>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          mx: "-1.5rem",
        }}
      >
        {blogData &&
          blogData.posts &&
          blogData.posts.map((blog, index) => {
            const {
              custom_excerpt: customExcerpt,
              feature_image: featureImage,
              title,
              url,
            } = blog;
            return (
              <Box
                key={`${camelCase(title)}_${index}`}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: "1.5rem",
                  width: ["100%", null, "calc(100% / 2)", "calc(100% / 3)"],
                }}
              >
                <LinkTo
                  hoverSx={{
                    bg: "primarySoft",
                    boxShadow: 2,
                    button: {
                      bg: "primary",
                      color: "white",
                      opacity: 1,
                    },
                    "h2, p": {
                      color: "primary",
                    },
                  }}
                  isLink={`${url}`}
                  sx={{
                    bg: "white",
                    button: {
                      opacity: 0,
                    },
                    display: "flex",
                    flex: "auto",
                    flexDirection: "column",
                    pb: 5,
                    position: "relative",
                    transition: "all .45s",
                  }}
                  target="_blank"
                >
                  <AspectRatio
                    ratio={[[4, 3], null, null, [1, 1]]}
                    sx={{
                      backgroundImage: `url(${featureImage})`,
                      backgroundSize: "cover",
                    }}
                  >
                    {/* <Image */}
                    {/*  img={featureImage} */}
                    {/*  sx={{ height: "100%", objectFit: "cover", width: "100%" }} */}
                    {/* /> */}
                  </AspectRatio>
                  <Box sx={{ p: "1.5rem" }}>
                    <Box
                      as="h2"
                      sx={{
                        fontSize: 2,
                        textTransform: "uppercase",
                      }}
                      variant="markdown.h2"
                    >
                      {title}
                    </Box>
                    {(customExcerpt && customExcerpt.substring(0, 80)) ||
                      "Read More"}
                    ...
                  </Box>
                  <Button
                    sx={{
                      bottom: 0,
                      position: "absolute",
                      right: 0,
                      transition: "all .45s",
                    }}
                  >
                    <AspectRatio sx={{ color: "inherit", width: "2.4rem" }}>
                      <Image svgIcon="arrow-right" />
                    </AspectRatio>
                  </Button>
                </LinkTo>
              </Box>
            );
          })}
      </Box>
    </Section>
  );
};

Insights.propTypes = {
  containerSx: PropTypes.shape({}),
  data: PropTypes.PropTypes.shape({
    actionButtons: PropTypes.arrayOf(PropTypes.shape({})),
    textContent: PropTypes.shape({
      content: PropTypes.string,
      heading: PropTypes.string,
    }),
  }),
  sectionSx: PropTypes.shape({}),
};

Insights.defaultProps = {
  containerSx: {},
  data: {},
  sectionSx: {},
};

export default Insights;
