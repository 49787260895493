import React, { useContext } from "react";
import PropTypes from "prop-types";
import { camelCase } from "change-case";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import AspectRatio from "../../components/AspectRatio";
import Image from "../../components/Image";
import LinkTo from "../../components/LinkTo";
import Section from "../../components/Section";
import Button from "../../components/Button";
import MarkdownToHtml from "../../components/MarkdownToHtml";
import ActionButtons from "../../components/ActionButtons";

const OurWork = ({ sectionSx, data, containerSx }) => {
  const theme = useContext(ThemeContext);
  return (
    <Section
      containerSx={{
        maxWidth: [null, "maxWidths.sm", "maxWidths.md", "maxWidths.lg", null],
        ...containerSx,
      }}
      sx={{ bg: "white", ...sectionSx }}
    >
      {!data.textContent.heading && (
        <Box
          as="h3"
          sx={{
            color: "primaryMedium",
            fontFamily: "secondary",
            fontSize: 2,
            fontWeight: "bold",
            letterSpacing: 4,
            lineHeight: 3,
            mb: 3,
            textTransform: "uppercase",
          }}
        >
          Our Work
        </Box>
      )}
      {data.textContent && (
        <Box
          sx={{
            alignItems: "flex-end",
            display: "flex",
            flexDirection: "column",
            mb: 4,
          }}
        >
          <MarkdownToHtml
            contentChildren={
              data.actionButtons &&
              data.actionButtons.length > 0 &&
              data.actionButtons.some(
                (i) => !i.hideButton && i.buttonLabel
              ) && (
                <ActionButtons
                  buttons={data.actionButtons}
                  wrapperSx={{
                    mt: 4,
                  }}
                />
              )
            }
            heading={data.textContent?.heading}
            source={data.textContent?.content}
            sx={{
              ".md_content": {
                flex: "auto",
                width: ["100%", null, null, "50%"],
              },
              ".md_heading": {
                pr: [null, null, null, 6],
                width: ["100%", null, null, "50%"],
              },
              display: "flex",
              flexWrap: "wrap",
              h3: "primaryMedium",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          m: -3,
        }}
      >
        {data.list.map((item, index) => (
          <Box
            key={`${camelCase(item.serviceName)}_${index}`}
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 3,
              width: ["100%", null, "calc(100% / 3)"],
            }}
          >
            <LinkTo
              hoverSx={{
                ".description": {
                  opacity: 1,
                },
                boxShadow: 2,
                button: {
                  bg: "primaryLight",
                  color: "primary",
                  opacity: 1,
                },
                color: "primary",
                h2: {
                  color: "white",
                },
              }}
              sx={{
                bg: "primaryLight",
                button: [
                  {
                    bg: "primaryLight",
                    color: "primary",
                    opacity: 1,
                  },
                  null,
                  null,
                  {
                    opacity: 0,
                  },
                ],
                color: "white",
                display: "inline-flex",
                flex: "auto",
                flexDirection: "column",
                h2: { color: ["white", null, null, "primary"] },
                overflow: "hidden",
                p: 3,
                position: "relative",
              }}
              to={`/work#${camelCase(item.serviceName)}`}
            >
              <Box as="h3" sx={{ color: "primary" }} variant="markdown.h3">
                {item.serviceName}
              </Box>
              <AspectRatio
                ratio={[4, 3]}
                sx={{ display: "flex", flex: "auto", flexDirection: "column" }}
              >
                <Image
                  img={item.image}
                  sx={{
                    height: "100%",
                    ml: "25%",
                    my: "auto",
                    objectFit: "contain",
                    width: "100%",
                  }}
                />
              </AspectRatio>
              <Box
                className="description"
                sx={{
                  bottom: 0,
                  display: "flex",
                  flexDirection: "column",
                  left: 0,
                  opacity: [1, null, null, 0],
                  position: "absolute",
                  right: 0,
                  top: 0,
                  transition: "all .45s",
                }}
              >
                <Box
                  className="overlay"
                  sx={{
                    backgroundImage: theme.baseGradients.primary[0],
                    bottom: 0,
                    left: 0,
                    opacity: [0.75, null, null, 1],
                    position: "absolute",
                    right: 0,
                    top: 0,
                  }}
                />
                <MarkdownToHtml
                  heading={item.textContent.heading}
                  source={item.textContent.content}
                  sx={{
                    ".md_content": {
                      flex: "auto",
                      overflow: "hidden",
                    },
                    bottom: "8.2rem",
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    h2: { ...theme.variants.markdown.h3 },
                    left: 0,
                    p: 3,
                    position: "absolute",
                    right: 0,
                    top: 0,
                  }}
                />
                <Button
                  sx={{
                    bottom: 0,
                    position: "absolute",
                    right: 0,
                    transition: "all .45s",
                  }}
                >
                  <AspectRatio sx={{ color: "inherit", width: "2.4rem" }}>
                    <Image svgIcon="arrow-right" />
                  </AspectRatio>
                </Button>
              </Box>
            </LinkTo>
          </Box>
        ))}
      </Box>
    </Section>
  );
};

OurWork.propTypes = {
  containerSx: PropTypes.shape({}),
  data: PropTypes.PropTypes.shape({
    actionButtons: PropTypes.arrayOf(PropTypes.shape({})),
    list: PropTypes.arrayOf(
      PropTypes.PropTypes.shape({
        content: PropTypes.string,
        image: PropTypes.string,
        serviceName: PropTypes.string,
      })
    ),
    textContent: PropTypes.shape({
      content: PropTypes.string,
      heading: PropTypes.string,
    }),
  }).isRequired,
  sectionSx: PropTypes.shape({}),
};

OurWork.defaultProps = {
  containerSx: {},
  sectionSx: {},
};

export default OurWork;
