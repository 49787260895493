import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const CareersContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allEventsJson(filter: { title: { ne: "Default" } }) {
          edges {
            node {
              id
              hideEvent
              title
              eventId
              images {
                image
              }
              summary {
                actionButtons {
                  buttonLabel
                  buttonPath
                  buttonType
                  externalLink
                  hideButton
                  simpleLink
                }
                hideSection
                textContent {
                  content
                  heading
                }
              }
            }
          }
        }
        allTeamMembersJson(
          sort: { fields: employeeCode, order: ASC }
          filter: { title: { ne: "Default [DO NOT REMOVE OR EDIT]" } }
        ) {
          edges {
            node {
              socialMediaLinks {
                linkedIn
                twitter
                facebook
              }
              employeeCode
              memberDesignation
              hideMember
              memberImage
              memberType
              featuredTestimonial
              testimonial
              title
            }
          }
        }
        allCultureJson(
          filter: { title: { ne: "Default [DO NOT REMOVE OR EDIT]" } }
        ) {
          edges {
            node {
              hideCulture
              id
              title
              summary {
                actionButtons {
                  buttonLabel
                  buttonPath
                  buttonType
                  externalLink
                  hideButton
                  simpleLink
                }
                images {
                  image
                }
                hideSection
                textContent {
                  content
                  heading
                }
              }
            }
          }
        }

        allOpeningsJson(filter: { title: { ne: "DEFAULT" } }) {
          edges {
            node {
              summary {
                location
                position
                numberOfOpenings
                type
              }
              brief {
                heading
                content
              }
              details
              jobRole
              openingId
              title
              id
              hideOpening
            }
          }
        }
        allCareersJson {
          edges {
            node {
              id
              pageTitle
              pageBanner {
                hideSection
                textContent {
                  heading
                  content
                }
                backgroundImage
              }
              introduction {
                actionButtons {
                  buttonLabel
                  buttonPath
                  buttonType
                  externalLink
                  hideButton
                  simpleLink
                }
                images {
                  image
                }
                hideSection
                textContent {
                  content
                  heading
                }
              }
              testimonials {
                heading
                displayOrder {
                  employeeName
                }
                hideSection
              }
              currentOpenings {
                heading
                hideSection
              }
            }
          }
        }
      }
    `}
    render={({
      allOpeningsJson: { edges: openingsData },
      allCareersJson: { edges: careersData },
      allCultureJson: { edges: cultureData },
      allEventsJson: { edges: eventsData },
      allTeamMembersJson: { edges: teamMembersData },
    }) =>
      children(
        careersData.map((e) => ({
          ...e.node,
        })),
        openingsData.map((e) => ({
          ...e.node,
        })),
        cultureData.map((e) => ({
          ...e.node,
        })),
        eventsData.map((e) => ({
          ...e.node,
        })),
        teamMembersData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

CareersContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default CareersContainer;
