import React from "react";
import { paramCase } from "change-case";
import PropTypes from "prop-types";
import IndustriesContainer from "../../containers/IndustriesContainer";
import PageBanner from "../../sections/PageBanner";
import PageIntro from "../../sections/PageIntro";
import PointsColumns from "../../sections/PointsColumns";
import Testimonials from "../../sections/Testimonials";
import scrollToSection from "../../utilities/scroll-to";
import { PAGE_ID } from "../../utilities/constants";
import CardsGrid from "../CardsGrid";

const IndustriesPage = ({ location }) => {
  const { hash } = location;
  return (
    <IndustriesContainer>
      {(industriesData, aboutData, clientsData, industriesListData) => {
        const pageName = PAGE_ID.INDUSTRIES;
        const industriesContent = industriesData
          .map((ele) => (ele.id === pageName ? ele : null))
          .find((el) => el);
        const {
          pageId,
          pageBanner,
          introduction,
          ourIndustries,
          ourApproach: industriesOurApproach,
          ourClients,
        } = industriesContent;

        const updatedIndustriesList =
          industriesListData &&
          industriesListData.length > 0 &&
          industriesListData
            .filter((f) => !f.hideIndustry)
            .map(
              (data) =>
                data && {
                  content: data.summary.content,
                  heading: data.summary.heading,
                  image: data.heroContent.image,
                  to: `/industries/${paramCase(data.title)}`,
                }
            );

        // industriesListData
        const isOurIndustries =
          ourIndustries &&
          !ourIndustries.hideSection &&
          industriesListData &&
          industriesListData.length > 0;

        // aboutContent
        const aboutContent = aboutData.find((data) => data.id === "about");
        const { ourApproach: defaultOurApproach } = aboutContent;

        // clientsContent
        const clientsContent = clientsData.find(
          (data) => data.id === "clients"
        );
        const { textContent: defaultContent, clients } = clientsContent;

        const showOurApproach =
          industriesOurApproach &&
          !industriesOurApproach.hideSection &&
          defaultOurApproach?.approachList?.length > 0;
        const ourApproachContent =
          (showOurApproach &&
            (industriesOurApproach.textContent.heading ||
              industriesOurApproach.textContent.content) &&
            industriesOurApproach.textContent) ||
          defaultOurApproach?.textContent;

        const isOurClients = ourClients && !ourClients.hideSection;
        const clientContent =
          (isOurClients &&
            (ourClients.textContent.heading ||
              ourClients.textContent.content) &&
            ourClients.textContent) ||
          defaultContent;

        setTimeout(() => scrollToSection(hash.slice(1)), 750);

        return (
          <>
            {pageBanner && !pageBanner.hideSection && (
              <PageBanner
                bannerContent={pageBanner.textContent}
                bgImage={pageBanner.backgroundImage}
              />
            )}
            {introduction && !introduction.hideSection && (
              <PageIntro
                data={introduction}
                imageSx={{
                  maxWidth: "100%",
                }}
                imageWrapperSx={{
                  mt: [0, null, null, "-20rem"],
                }}
                page={pageId}
                sectionSx={{ bg: "white" }}
              />
            )}
            {isOurIndustries && (
              <CardsGrid
                data={updatedIndustriesList}
                headingSx={{ bg: "white", minHeight: "10rem", pb: 0 }}
                sectionSx={{
                  ":before": {
                    bg: "grays.0",
                    content: "''",
                    display: ["none", null, null, "block"],
                    height: "50%",
                    position: "absolute",
                    top: "25%",
                    width: "100%",
                  },
                  bg: "white",
                  position: "relative",
                }}
                sectionTitle="Key Industries"
              />
            )}
            {showOurApproach && (
              <PointsColumns
                data={{
                  ...defaultOurApproach,
                  textContent: ourApproachContent,
                }}
                sectionSx={{ bg: "primarySoft" }}
              />
            )}
            {isOurClients && (
              <Testimonials
                data={{
                  clients,
                  textContent: clientContent,
                }}
              />
            )}
          </>
        );
      }}
    </IndustriesContainer>
  );
};

IndustriesPage.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
  }),
};

IndustriesPage.defaultProps = {
  location: null,
};

export default IndustriesPage;
