import React from "react";
import PropTypes from "prop-types";
import { camelCase } from "change-case";
import Box from "../../base/Box";
import MarkdownToHtml from "../../components/MarkdownToHtml";
import AspectRatio from "../../components/AspectRatio";
import BgImage from "../../components/BgImage";
import ActionButtons from "../../components/ActionButtons";
import Section from "../../components/Section";
import Image from "../../components/Image";

const PageIntro = ({
  sectionSx,
  data,
  containerSx,
  page,
  contentSx,
  contentWrapperSx,
  imageSx,
  imageRatio,
  imageWrapperSx,
}) => (
  <Section
    bgImage={data.backgroundImage}
    containerSx={{
      display: "flex",
      flexDirection: ["column", null, null, "row-reverse"],
      ...containerSx,
    }}
    sx={sectionSx}
  >
    {data.images && data.images.length > 0 && data.images.some((i) => i.image) && (
      <Box
        sx={{
          display: "flex",
          flex: "auto",
          mb: [3, null, null, 0],
          mx: -2,
          ...imageWrapperSx,
        }}
      >
        {data.images.map((media, index) => (
          <Box
            key={`${camelCase(media.image)}-${index}`}
            sx={{
              flex: "auto",
              maxWidth: data.images.length > 1 && index === 1 ? "45%" : "",
              px: 2,
              ...imageSx,
            }}
          >
            <AspectRatio
              key={`${media.image}-${index}`}
              ratio={imageRatio || [400, 620]}
              sx={{
                maxWidth: "100%",
              }}
            >
              {page ? (
                <BgImage
                  name={media.image}
                  page={page}
                  sx={{
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    position: "absolute",
                  }}
                />
              ) : (
                <Image
                  img={media.image}
                  sx={{
                    height: "auto",
                    objectFit: "cover",
                    width: "100%",
                    ...imageSx,
                  }}
                />
              )}
            </AspectRatio>
          </Box>
        ))}
      </Box>
    )}
    <Box
      sx={{
        pr: [null, null, null, 4],
        width: [null, null, null, "50%"],
        ...contentWrapperSx,
      }}
    >
      <MarkdownToHtml
        heading={data?.textContent?.heading}
        source={data?.textContent?.content}
        sx={{
          h2: {
            color: "text.heading",
            fontWeight: "medium",
          },
          h3: {
            color: "primaryLight",
          },
          ...contentSx,
        }}
      />
      {data.actionButtons &&
        data.actionButtons.length > 0 &&
        data.actionButtons.some((i) => !i.hideButton && i.buttonLabel) && (
          <ActionButtons buttons={data.actionButtons} />
        )}
    </Box>
  </Section>
);

PageIntro.propTypes = {
  containerSx: PropTypes.shape({}),
  contentSx: PropTypes.shape({}),
  contentWrapperSx: PropTypes.shape({}),
  data: PropTypes.PropTypes.shape({
    actionButtons: PropTypes.arrayOf(PropTypes.shape({})),
    backgroundImage: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.shape({})),
    textContent: PropTypes.shape({
      content: PropTypes.string,
      heading: PropTypes.string,
    }).isRequired,
  }).isRequired,
  imageRatio: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number])
  ),
  imageSx: PropTypes.shape({}),
  imageWrapperSx: PropTypes.shape({}),
  page: PropTypes.string.isRequired,
  sectionSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

PageIntro.defaultProps = {
  containerSx: {},
  contentSx: {},
  contentWrapperSx: {},
  imageRatio: [400, 620],
  imageSx: {},
  imageWrapperSx: {},
  sectionSx: {},
  sx: {},
};

export default PageIntro;
