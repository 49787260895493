import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const HomePageContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allHomePageJson {
          edges {
            node {
              id
              heroBanner {
                hideSection
                textContent {
                  heading
                  content
                }
                bannerImage
                backgroundImage
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              introduction {
                hideSection
                textContent {
                  heading
                  content
                }
                images {
                  image
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
                backgroundImage
              }
              keyCompetencies {
                hideSection
                textContent {
                  heading
                  content
                }
              }
              ourWork {
                hideSection
                textContent {
                  heading
                  content
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              insights {
                hideSection
                textContent {
                  heading
                  content
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              ourClients {
                hideSection
                textContent {
                  heading
                  content
                }
              }
              ourApproach {
                hideSection
                textContent {
                  heading
                  content
                }
              }
            }
          }
        }
        allAboutJson {
          edges {
            node {
              id
              pageId
              ourApproach {
                hideSection
                textContent {
                  heading
                  content
                }
                artworkImage
                approachList {
                  content
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
            }
          }
        }
        allServicesJson {
          edges {
            node {
              id
              pageId
              keyCompetencies {
                textContent {
                  heading
                  content
                }
              }
              services {
                hideService
                serviceName
                keyCompetency
                briefing
              }
            }
          }
        }
        allWorkJson {
          edges {
            node {
              id
              pageId
              services {
                hideService
                serviceName
                textContent {
                  heading
                  content
                }
                image
              }
            }
          }
        }
        allClientsJson {
          edges {
            node {
              id
              pageTitle
              textContent {
                heading
                content
              }
              clients {
                logo
                clientName
                clientUrl
                testimonial
                authorName
                authorDesignation
              }
            }
          }
        }
      }
    `}
    render={({
      allHomePageJson: { edges: homePageData },
      allAboutJson: { edges: aboutData },
      allServicesJson: { edges: servicesData },
      allWorkJson: { edges: workData },
      allClientsJson: { edges: clientsData },
    }) =>
      children(
        homePageData.map((e) => ({
          ...e.node,
        })),
        aboutData.map((e) => ({
          ...e.node,
        })),
        servicesData.map((e) => ({
          ...e.node,
        })),
        workData.map((e) => ({
          ...e.node,
        })),
        clientsData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

HomePageContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default HomePageContainer;
