import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";
import { camelCase, paramCase } from "change-case";
import Section from "../Section";
import PageBanner from "../../sections/PageBanner";
import MarkdownToHtml from "../MarkdownToHtml";
import ActionButtons from "../ActionButtons";
import Box from "../../base/Box";
import AspectRatio from "../AspectRatio";
import Image from "../Image";
import ServicesContainer from "../../containers/ServicesContainer";
import PageIntro from "../../sections/PageIntro";
import { CommaStringToArray } from "../../helper/helper";
import Carousel from "../Carousel";
import CustomArrow from "../Carousel/CustomArrow";
import LinkTo from "../LinkTo";
import Heading from "../Heading";
import HorizontalCard from "../../sections/HorizontalCard";

const ServiceLayout = ({ pageContext }) => {
  const {
    serviceData: { id },
  } = pageContext;
  const theme = useContext(ThemeContext);
  const imageHandler = (item) => (
    <AspectRatio
      ratio={[730, 350]}
      sx={{
        img: {
          height: "100%",
          objectFit: "contain",
          objectPosition: "center",
          width: "100%",
        },
        width: "90%",
      }}
    >
      <Image img={item.image} title={item.name} />
    </AspectRatio>
  );
  return (
    <ServicesContainer>
      {(servicesData, serviceData, benefitsData, blogsData) => {
        const { summary, introduction, ctaSection, featuredBlogs, technology } =
          serviceData.find((service) => paramCase(service.id) === id);
        const [benefitsContent] = benefitsData.filter(
          (data) => data.title === "benefits"
        );

        const featuredBlogList =
          featuredBlogs && CommaStringToArray(featuredBlogs);
        const featuredBlogCollection =
          featuredBlogList &&
          blogsData.filter((b) => featuredBlogList.includes(b.blogId));
        const prevStyled = {
          left: 0,
        };
        const nextStyled = {
          right: 0,
        };
        const controlStyled = {
          ":hover": {
            bg: "white",
            color: "primaryLight",
          },
          color: "grays.2",
          position: "absolute",
          top: "50%",
        };

        return (
          <>
            <PageBanner
              bannerContent={{
                heading: summary.heading,
              }}
              containerSx={{
                mb: "auto",
                pb: [5, null, null, null, 2],
                position: "relative",
                pt: [5, null, null, null, 6],
              }}
              contentSx={{
                ".md_heading": {
                  fontSize: 5,
                  fontWeight: "bold",
                  mb: 3,
                },
                fontSize: 2,
                h2: {
                  ...theme.variants.markdown.h1,
                  color: "primary",
                },
                h3: {
                  color: "grays.2",
                },
                p: {
                  display: "none",
                },
                width: ["100%", null, null, "50%"],
              }}
              sx={{
                bg: "grays.0",
                minHeight: "25rem",
              }}
            />
            {introduction && !introduction.hideSection && (
              <Section
                containerSx={{
                  display: "flex",
                  flexDirection: ["column", null, null, "row-reverse"],
                }}
              >
                {introduction.images &&
                  introduction.images.length > 0 &&
                  introduction.images.some((i) => i.image) && (
                    <Box
                      sx={{
                        display: "flex",
                        flex: "auto",
                        maxWidth: ["100%", null, null, "50%"],
                        mb: [4, null, null, 0],
                        mt: [0, null, null, "-15rem"],
                        mx: "auto",
                        width: ["100%", null, null, "50%"],
                      }}
                    >
                      {introduction.images.map((media, index) => (
                        <AspectRatio
                          key={`${media.image}-${index}`}
                          ratio={[16, 9]}
                          sx={{
                            img: {
                              mx: "auto",
                            },
                            px: 2,
                          }}
                        >
                          <Image
                            img={media.image}
                            sx={{
                              objectFit: "contain",
                            }}
                          />
                        </AspectRatio>
                      ))}
                    </Box>
                  )}
                <Box
                  sx={{
                    mb: [4, null, null, null],
                    pr: [null, null, null, 4],
                    width: [null, null, null, "50%"],
                  }}
                >
                  <MarkdownToHtml
                    heading={introduction.heading}
                    source={introduction.content}
                    sx={{
                      h2: {
                        color: "text.heading",
                        fontWeight: "medium",
                      },
                      h3: {
                        color: "primaryLight",
                      },
                    }}
                  />
                  {introduction.actionButtons &&
                    introduction.actionButtons.length > 0 &&
                    introduction.actionButtons.some(
                      (i) => !i.hideButton && i.buttonLabel
                    ) && <ActionButtons buttons={introduction.actionButtons} />}
                </Box>
              </Section>
            )}
            {benefitsContent && !benefitsContent.hideSection && (
              <Section sx={{ bg: "primary", display: "flex" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {benefitsContent.benefits &&
                    benefitsContent.benefits.length > 0 &&
                    benefitsContent.benefits.map(
                      (benefit) =>
                        !benefit.hideBenefit && (
                          <MarkdownToHtml
                            content={benefit.content}
                            sx={{
                              color: "primaryLight",
                              display: "flex",
                              flexDirection: "column",
                              h2: {
                                color: "primaryLight",
                                mb: 3,
                              },
                              h3: {
                                color: "primaryLight",
                              },
                              p: 3,
                              width: ["100%", "50%", null, "25%"],
                            }}
                          />
                        )
                    )}
                </Box>
              </Section>
            )}
            {featuredBlogCollection &&
              featuredBlogCollection.length > 0 &&
              featuredBlogCollection.map(
                (blog, index) =>
                  blog &&
                  !blog.hideBlog && (
                    <HorizontalCard
                      key={`${paramCase(blog.title)}-${index}`}
                      data={{
                        actionButtons: blog?.summary?.actionButtons,
                        image: blog?.summary?.image,
                        textContent: {
                          content: blog?.summary?.content,
                          heading: blog?.summary?.heading,
                        },
                      }}
                      imageSx={{
                        height: "100% !important",
                        objectFit: "cover",
                        width: "100%",
                      }}
                      isEven={(index + 1) % 2 === 0}
                      sectionSx={{
                        bg: "white",
                        pb:
                          index === 0
                            ? [4, null, null, null, 0]
                            : [5, null, null, null, 6],
                        pt:
                          index !== 0
                            ? [4, null, null, null, 0]
                            : [5, null, null, null, 6],
                      }}
                      textContentSx={{
                        h2: {
                          color: "black",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  )
              )}
            {technology?.technologyList?.length > 0 && !technology.hideSection && (
              <Section
                containerSx={{
                  display: "flex",
                  flex: "auto",
                  flexDirection: "column",
                  maxWidth: "100%",
                  width: "100%",
                }}
                sx={{
                  bg: "white",
                  display: "flex",
                  flexDirection: "column",
                  mb: 0,
                  mt: 0,
                  pt: 0,
                }}
              >
                <Heading
                  sx={{
                    color: "black",
                    fontFamily: "secondary",
                    fontSize: [4, null, null, 5],
                    fontWeight: "bold",
                    my: [4, null, null, 6],
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                >
                  {technology.heading}
                </Heading>
                <Carousel
                  settings={{
                    arrows: true,
                    centerMode: true,
                    infinite: true,
                    nextArrow: (
                      <CustomArrow nextSx={nextStyled} sx={controlStyled} />
                    ),
                    prevArrow: (
                      <CustomArrow
                        prev
                        prevSx={prevStyled}
                        sx={controlStyled}
                      />
                    ),
                    slidesToScroll: 1,
                    slidesToShow: 5,
                    speed: 500,
                  }}
                  sx={{
                    "& > .custom-arrow": {
                      opacity: 0,
                      transition: "all 0.35s",
                    },
                    "&:hover > .custom-arrow": { opacity: 1 },
                    "&:hover > .custom-arrow-next": { right: 2 },
                    "&:hover > .custom-arrow-prev": { left: 2 },
                    mx: -2,
                  }}
                >
                  {technology &&
                    !technology.hideSection &&
                    technology.technologyList?.map(
                      (item, index) =>
                        item.image &&
                        (item.link ? (
                          <LinkTo
                            key={`${camelCase(item.image)}-${index}`}
                            isLink={item.link}
                            sx={{
                              alignItems: "center",
                              display: "flex !important",
                              justifyContent: "center",
                              px: 2,
                            }}
                            target="_blank"
                          >
                            {imageHandler(item)}
                          </LinkTo>
                        ) : (
                          <Box
                            key={`${camelCase(item.image)}-${index}`}
                            sx={{
                              alignItems: "center",
                              display: "flex !important",
                              px: 2,
                            }}
                          >
                            {imageHandler(item)}
                          </Box>
                        ))
                    )}
                </Carousel>
              </Section>
            )}
            {ctaSection && !ctaSection.hideSection && (
              <PageIntro
                contentSx={{
                  h2: {
                    color: "primary",
                    fontSize: 5,
                    fontWeight: "medium",
                  },
                }}
                contentWrapperSx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  pr: 0,
                  textAlign: "center",
                  width: "100%",
                }}
                data={ctaSection}
                page="service"
                sectionSx={{
                  bg: "grays.0",
                }}
              />
            )}
          </>
        );
      }}
    </ServicesContainer>
  );
};

ServiceLayout.propTypes = {
  pageContext: PropTypes.shape({
    serviceData: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ServiceLayout;
