import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { camelCase } from "change-case";
import BrandLogo from "../BrandLogo";
import FooterContainer from "../../containers/FooterContainer";
import Box from "../../base/Box";
import Section from "../Section";
import MarkdownToHtml from "../MarkdownToHtml";
import ActionButtons from "../ActionButtons";
import Copyright from "../Copyright";
import LinkTo from "../LinkTo";
import AspectRatio from "../AspectRatio";
import Image from "../Image";

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        site {
          siteMetadata {
            logo
            siteName
          }
        }
      }
    `}
    render={(data) => {
      const { siteName, logo } = data.site.siteMetadata;
      const setLink = (link, linksType) => {
        const isIcon = linksType === "icon";
        const linkProps = link.externalLink
          ? {
              isLink: link.linkPath || "/",
              target: "_blank",
            }
          : { to: link.linkPath || "/" };
        return (
          <Box
            key={camelCase(link.linkLabel)}
            as="li"
            sx={{
              "&:not(:last-child)": { mb: 2 },
              ...(isIcon ? { px: 2 } : {}),
            }}
          >
            <LinkTo
              activeSx={{ color: "primaryLight" }}
              hoverSx={{ color: "primaryLight" }}
              {...linkProps}
            >
              {isIcon ? (
                <AspectRatio sx={{ width: "2.4rem" }}>
                  <Image img={link.iconImage} />
                </AspectRatio>
              ) : (
                link.linkLabel
              )}
            </LinkTo>
          </Box>
        );
      };
      return (
        <FooterContainer>
          {(footerData, pagesData) => {
            const footerContent = footerData.find((i) => i.id === "footer");
            const allPagesList = pagesData.find((i) => i.id === "pages");
            const {
              backgroundImage,
              callToAction,
              copyrightText,
              quickContent,
              quickLinks,
              footerLinks,
            } = footerContent;
            const { pages } = allPagesList;
            const showQuickLinks =
              quickLinks &&
              !quickLinks.hideQuickLinks &&
              pages &&
              pages.length > 0;

            const footerLinksList = showQuickLinks
              ? [
                  {
                    groupLinks: pages
                      .filter((p) => !p.hidePage)
                      .map((p) => ({
                        externalLink: "",
                        iconImage: "",
                        linkLabel: p.title,
                        linkPath: p.url,
                      })),
                    groupTitle: quickLinks.title,
                    hideGroup: quickLinks.hideQuickLinks,
                    linksType: "simple",
                  },
                  ...footerLinks,
                ]
              : footerLinks;

            return (
              <Section
                bgImage={backgroundImage}
                containerSx={{ display: "flex", flexDirection: "column" }}
                sx={{ color: "white", mb: 0, mt: 0 }}
              >
                {callToAction && !callToAction.hideSection && (
                  <Box
                    sx={{
                      alignItems: "end",
                      border: "1px solid",
                      borderColor: "primaryTransparent.1",
                      borderWidth: " 0 0 1px 0",
                      display: "flex",
                      flexWrap: "wrap",
                      mb: 4,
                      pb: 4,
                    }}
                  >
                    <MarkdownToHtml
                      heading={callToAction.textContent.heading}
                      source={callToAction.textContent.content}
                      sx={{
                        flex: "auto",
                        h2: { color: "white" },
                        h3: {
                          color: "primaryLight",
                        },
                      }}
                    />
                    <ActionButtons
                      buttons={callToAction.actionButtons}
                      wrapperSx={{ mt: 0 }}
                    />
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: ["column", null, null, "row"],
                    mx: -3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flex: "1",
                      flexDirection: "column",
                      maxWidth: [null, null, null, "33.33%"],
                      p: 3,
                    }}
                  >
                    <BrandLogo
                      alt={siteName}
                      path="/"
                      ratio={[94, 32]}
                      src={logo}
                      sx={{
                        color: "white",
                        flex: "none",
                        mb: 3,
                        width: "9.4rem",
                      }}
                    />
                    {quickContent && (
                      <MarkdownToHtml
                        source={quickContent}
                        sx={{
                          "& > div > span > *:last-child": {
                            mb: 0,
                          },
                          a: {
                            "&:hover": {
                              color: "inherit",
                              textDecoration: "underline",
                            },
                            textDecoration: "none",
                          },
                        }}
                      />
                    )}
                  </Box>
                  {footerLinksList && footerLinksList.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        flex: "auto",
                        flexDirection: ["column", null, "row"],
                        justifyContent: "flex-end",
                        // mx: -3,
                      }}
                    >
                      {footerLinksList
                        .filter((g) => !g.hideGroup)
                        .map((group, index) => {
                          const { groupLinks, groupTitle, linksType } = group;
                          return (
                            <Box
                              key={`${camelCase(groupTitle)}-${index}`}
                              sx={{
                                display: "flex",
                                flex: "1",
                                flexDirection: "column",
                                maxWidth: [null, null, "50%", "33.33%"],
                                p: 3,
                              }}
                            >
                              <Box
                                as="h3"
                                sx={{ color: "inherit", mb: 3 }}
                                variant="markdown.h3"
                              >
                                {groupTitle}
                              </Box>
                              {groupLinks && groupLinks.length > 0 && (
                                <Box
                                  as="ul"
                                  sx={
                                    linksType === "icon"
                                      ? {
                                          display: "flex",
                                          flexWrap: "wrap",
                                          mx: -2,
                                        }
                                      : {}
                                  }
                                >
                                  {groupLinks.map((link) =>
                                    setLink(link, linksType)
                                  )}
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                    </Box>
                  )}
                </Box>
                <Box sx={{ pt: 4 }}>
                  {copyrightText && (
                    <Copyright append={`© ${copyrightText}`} prepend="" />
                  )}
                </Box>
              </Section>
            );
          }}
        </FooterContainer>
      );
    }}
  />
);

export default Footer;
