/* Everestek Website 2021 */
import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import HomePageContainer from "../../containers/HomePageContainer";
import BgImage from "../BgImage";
import HeroBanner from "../HeroBanner";
import AspectRatio from "../AspectRatio";
import MarkdownToHtml from "../MarkdownToHtml";
import ActionButtons from "../ActionButtons";
import Testimonials from "../../sections/Testimonials";
import PointsColumns from "../../sections/PointsColumns";
import CardsGrid from "../../sections/CardsGrid";
import PageIntro from "../../sections/PageIntro";
import Insights from "../../sections/Insights";
import OurWork from "../../sections/OurWork";

const HomePage = () => {
  const theme = useContext(ThemeContext);
  return (
    <HomePageContainer>
      {(homePageData, aboutData, servicesData, workData, clientsData) => {
        // homeContent
        const pageId = "home";
        const homeContent = homePageData
          .map((ele) => (ele.id === pageId ? ele : null))
          .find((el) => el);
        const {
          keyCompetencies: homePageKeyCompetencies,
          ourWork: homePageOurWork,
          ourApproach: homePageOurApproach,
          heroBanner,
          insights,
          ourClients,
          introduction,
        } = homeContent;

        // aboutContent
        const aboutContent = aboutData.find((data) => data.id === "about");
        const { ourApproach: defaultOurApproach } = aboutContent;

        // servicesContent
        const servicesContent = servicesData.find(
          (data) => data.id === "services"
        );
        const { keyCompetencies: defaultKeyCompetencies, services } =
          servicesContent;

        // workData
        const workServicesContent = workData
          .map((ele) => ele.id === "work" && ele)
          .find((el) => el);
        const { services: workServices } = workServicesContent;

        // clientsContent
        const clientsContent = clientsData.find(
          (data) => data.id === "clients"
        );
        const { textContent: defaultClientContent, clients } = clientsContent;

        const isKeyCompetencies =
          homePageKeyCompetencies || defaultKeyCompetencies;
        const showKeyCompetencies =
          isKeyCompetencies &&
          !homePageKeyCompetencies.hideSection &&
          services.length > 0;
        const KeyCompetenciesContent =
          showKeyCompetencies && isKeyCompetencies.textContent;

        const showOurApproach =
          homePageOurApproach &&
          !homePageOurApproach.hideSection &&
          defaultOurApproach?.approachList?.length > 0;
        const ourApproachContent =
          (showOurApproach &&
            (homePageOurApproach?.textContent?.heading ||
              homePageOurApproach?.textContent?.content) &&
            homePageOurApproach?.textContent) ||
          defaultOurApproach?.textContent;

        const isInsights = insights && !insights.hideSection;
        const insightsContent = isInsights && insights.textContent;
        const insightsActionButtons =
          (isInsights && insights.actionButtons) || [];

        const isOurWork = homePageOurWork && !homePageOurWork.hideSection;
        const ourWorkContent = isOurWork && homePageOurWork.textContent;
        const ourWorkActionButtons =
          (isOurWork && homePageOurWork.actionButtons) || [];
        const ourWorkServiceList =
          isOurWork &&
          workServices &&
          workServices.length > 0 &&
          workServices.filter((i) => !i.hideService).splice(0, 4);

        const isOurClients = ourClients && !ourClients.hideSection;
        const clientContent =
          (isOurClients &&
            (ourClients.textContent?.heading ||
              ourClients.textContent?.content) &&
            ourClients.textContent) ||
          defaultClientContent;

        return (
          <>
            {heroBanner && !heroBanner.hideSection && (
              <HeroBanner
                containerSx={{ flexDirection: "column" }}
                sx={{ minHeight: ["25rem", null, null, "50rem"], zIndex: 1 }}
              >
                <MarkdownToHtml
                  content={heroBanner?.textContent?.content}
                  heading={heroBanner?.textContent?.heading}
                  sx={{ maxWidth: [null, null, null, "70%"] }}
                />
                {heroBanner.actionButtons &&
                  heroBanner.actionButtons.length > 0 && (
                    <ActionButtons buttons={heroBanner.actionButtons} />
                  )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: ["center", null, null, "flex-end"],
                    left: "50%",
                    mb: [null, null, null, `calc(${theme.space[6]} * -2)`],
                    mt: [4, null, null, 0],
                    position: "relative",
                    transform: "translateX(-50vw)",
                    width: "100vw",
                  }}
                >
                  <AspectRatio
                    ratio={[16, 9]}
                    sx={{
                      maxWidth: ["100%", null, null, "50%"],
                    }}
                  >
                    <BgImage
                      name={heroBanner.bannerImage}
                      page={homeContent.id}
                      sx={{
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        left: 0,
                        position: "absolute",
                        top: 0,
                      }}
                    />
                  </AspectRatio>
                </Box>
              </HeroBanner>
            )}
            {introduction && !introduction.hideSection && (
              <PageIntro
                containerSx={{
                  flexDirection: ["column-reverse", null, null, "row"],
                  mb: [
                    null,
                    null,
                    null,
                    `calc(${theme.space[6]} * 5)`,
                    `calc(${theme.space[6]} * 4)`,
                  ],
                  mt: [
                    null,
                    null,
                    null,
                    `calc(${theme.space[6]} * 2)`,
                    `calc(${theme.space[6]} * 1)`,
                  ],
                }}
                contentSx={{
                  color: "white",
                  h2: {
                    color: "white",
                  },
                }}
                contentWrapperSx={{
                  mb: [4, null, null, null, 0],
                  mt: [null, null, null, null, 8],
                  pl: [null, null, null, 6],
                  pr: 0,
                }}
                data={introduction}
                imageWrapperSx={{
                  alignItems: [null, null, null, null, "flex-end"],
                }}
                page={pageId}
                sectionSx={{
                  mb: [
                    null,
                    null,
                    null,
                    `calc(${theme.space[6]} * -5)`,
                    `calc(${theme.space[6]} * -4)`,
                  ],
                }}
              />
            )}
            {showKeyCompetencies && (
              <CardsGrid
                contentSx={{
                  color: [null, null, null, "white"],
                  h2: { color: [null, null, null, "inherit"] },
                }}
                contentWrapperSx={{ mb: 0 }}
                data={{ services, textContent: KeyCompetenciesContent }}
                sectionSx={{ bg: "transparent" }}
              />
            )}
            {isOurWork && (
              <OurWork
                data={{
                  actionButtons: ourWorkActionButtons,
                  list: ourWorkServiceList,
                  textContent: ourWorkContent,
                }}
              />
            )}
            {showOurApproach && (
              <PointsColumns
                data={{
                  ...defaultOurApproach,
                  textContent: ourApproachContent,
                }}
              />
            )}
            {isInsights && (
              <Insights
                data={{
                  actionButtons: insightsActionButtons,
                  textContent: insightsContent,
                }}
              />
            )}
            {isOurClients && (
              <Testimonials
                data={{
                  clients,
                  textContent: clientContent,
                }}
              />
            )}
          </>
        );
      }}
    </HomePageContainer>
  );
};

export default HomePage;
